<template>
  <m-modal
    :show="show"
    :title="title"
    @close="onClose"
    widthClasses="w-full lg:w-3/6"
    heightStyle="max-height: 80vh;"
    data-recording-sensitive
  >
    <div class="modal-content mx-3">
      <div class="overflow-y-auto">
        <!-- MAIN MENU -->
        <div class="flex flex-col" v-show="page == 'main'">
          <!-- <div class="flex px-3 font-medium mb-2 text-grey1">
            Share
            <m-select
              class="ml-1 text-base2 font-medium"
              v-model="shareType"
              :options="shareTypeValues"
              title=""
            ></m-select>
          </div> -->
          <template v-if="modalType == 'url'">
            <div class="px-3 font-medium mb-2 text-grey1">
              Give access to meeting guests not signed in to Meetric
            </div>
            <preference-item class="px-3">
              <template v-slot:icon>
                <div
                  style="width: 24px; height: 24px"
                  class="text-grey3 stroke-current"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="mx-auto"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path
                      d="M10 14a3.5 3.5 0 0 0 5 0l4 -4a3.5 3.5 0 0 0 -5 -5l-.5 .5"
                    />
                    <path
                      d="M14 10a3.5 3.5 0 0 0 -5 0l-4 4a3.5 3.5 0 0 0 5 5l.5 -.5"
                    />
                  </svg>
                </div>
              </template>
              <template v-slot:title> URL </template>
              <template v-slot:subtitle> Anyone with the link </template>
              <template v-slot:control>
                <m-toggle v-model="sharePageOption"></m-toggle
              ></template>
            </preference-item>
            <div v-if="sharePageOption" class="flex mt-2 mx-3">
              <div
                class="share-url flex flex-col flex-grow justify-center h-9 select-text select-all bg-grey6 rounded px-3 text-grey2 overflow-hidden whitespace-nowrap overflow-ellipsis font-normal cursor-text focus:overflow-clip focus:outline-none focus:ring-2 focus:ring-grey5"
              >
                <input
                  readonly
                  class="truncate bg-grey6 text-grey2 rounded focus:outline-none focus:overflow-clip"
                  v-model="currentUrl"
                />
              </div>

              <!-- Copy icon -->
              <div
                class="share-url flex flex-col justify-center bg-grey6 rounded-r h-9 px-3 w-auto border border-solid border-grey5 dark:border-grey6 border-t-0 border-r-0 border-b-0 cursor-pointer font-medium text-base2"
                @click="copyLink"
                title="Copy link"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  class="text-base2 hover:opacity-75"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <rect
                    stroke="currentColor"
                    x="8"
                    y="8"
                    width="12"
                    height="12"
                    rx="2"
                  />
                  <path
                    stroke="currentColor"
                    d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2"
                  />
                </svg>
              </div>

              <m-select
                class="ml-4 text-grey1 font-medium"
                v-model="shareLinkValue"
                :options="shareLinkOptions"
                title="Allow guests to view vs edit the notes"
              ></m-select>
            </div>
          </template>

          <template v-if="modalType == 'note'">
            <preference-item class="px-3">
              <template v-slot:icon>
                <div style="width: 24px; height: 24px">
                  <svg
                    class="mx-auto"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    width="20"
                    height="20"
                    stroke="none"
                  >
                    <path
                      d="M440 6.5L24 246.4c-34.4 19.9-31.1 70.8 5.7 85.9L144 379.6V464c0 46.4 59.2 65.5 86.6 28.6l43.8-59.1 111.9 46.2c5.9 2.4 12.1 3.6 18.3 3.6 8.2 0 16.3-2.1 23.6-6.2 12.8-7.2 21.6-20 23.9-34.5l59.4-387.2c6.1-40.1-36.9-68.8-71.5-48.9zM192 464v-64.6l36.6 15.1L192 464zm212.6-28.7l-153.8-63.5L391 169.5c10.7-15.5-9.5-33.5-23.7-21.2L155.8 332.6 48 288 464 48l-59.4 387.3z"
                    ></path>
                  </svg>
                </div>
              </template>
              <template v-slot:title> Email </template>
              <template v-slot:subtitle>
                Send to&nbsp;<a-link @click.native="page = 'notesrecipients'"
                  >{{ sendToList.length }} recipient<span
                    v-if="sendToList.length != 1"
                    >s</span
                  ></a-link
                >&nbsp;now
              </template>
              <template v-slot:control>
                <m-toggle v-model="sendNotesOption"></m-toggle
              ></template>
            </preference-item>

            <preference-item v-if="slackConnected" class="px-3">
              <template v-slot:icon>
                <svg
                  height="24"
                  width="24"
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="57 57 150 150"
                  xml:space="preserve"
                >
                  <g>
                    <g>
                      <path
                        d="M99.4,151.2c0,7.1-5.8,12.9-12.9,12.9c-7.1,0-12.9-5.8-12.9-12.9c0-7.1,5.8-12.9,12.9-12.9h12.9V151.2z"
                      />
                      <path
                        d="M105.9,151.2c0-7.1,5.8-12.9,12.9-12.9s12.9,5.8,12.9,12.9v32.3c0,7.1-5.8,12.9-12.9,12.9
			s-12.9-5.8-12.9-12.9V151.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M118.8,99.4c-7.1,0-12.9-5.8-12.9-12.9c0-7.1,5.8-12.9,12.9-12.9s12.9,5.8,12.9,12.9v12.9H118.8z"
                      />
                      <path
                        d="M118.8,105.9c7.1,0,12.9,5.8,12.9,12.9s-5.8,12.9-12.9,12.9H86.5c-7.1,0-12.9-5.8-12.9-12.9
			s5.8-12.9,12.9-12.9H118.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M170.6,118.8c0-7.1,5.8-12.9,12.9-12.9c7.1,0,12.9,5.8,12.9,12.9s-5.8,12.9-12.9,12.9h-12.9V118.8z"
                      />
                      <path
                        d="M164.1,118.8c0,7.1-5.8,12.9-12.9,12.9c-7.1,0-12.9-5.8-12.9-12.9V86.5c0-7.1,5.8-12.9,12.9-12.9
			c7.1,0,12.9,5.8,12.9,12.9V118.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M151.2,170.6c7.1,0,12.9,5.8,12.9,12.9c0,7.1-5.8,12.9-12.9,12.9c-7.1,0-12.9-5.8-12.9-12.9v-12.9H151.2z"
                      />
                      <path
                        d="M151.2,164.1c-7.1,0-12.9-5.8-12.9-12.9c0-7.1,5.8-12.9,12.9-12.9h32.3c7.1,0,12.9,5.8,12.9,12.9
			c0,7.1-5.8,12.9-12.9,12.9H151.2z"
                      />
                    </g>
                  </g>
                </svg>
              </template>
              <template v-slot:title>Slack</template>
              <template v-slot:subtitle>
                <div class="flex">
                  Send to #{{ slackDetails.default_channel.name }} now
                </div>
              </template>
              <template v-slot:control
                ><m-toggle v-model="exportSlackOption"></m-toggle
              ></template>
            </preference-item>

            <preference-item
              v-if="notionConnected && shareType == 'notes'"
              class="px-3"
            >
              <template v-slot:icon>
                <svg
                  height="24"
                  width="24"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="12 0.18999999999999906 487.619 510.941"
                >
                  <path
                    class="stroke-current"
                    d="M96.085 91.118c15.81 12.845 21.741 11.865 51.43 9.884l279.888-16.806c5.936 0 1-5.922-.98-6.906L379.94 43.686c-8.907-6.915-20.773-14.834-43.516-12.853L65.408 50.6c-9.884.98-11.858 5.922-7.922 9.883zm16.804 65.228v294.491c0 15.827 7.909 21.748 25.71 20.769l307.597-17.799c17.81-.979 19.794-11.865 19.794-24.722V136.57c0-12.836-4.938-19.758-15.84-18.77l-321.442 18.77c-11.863.997-15.82 6.931-15.82 19.776zm303.659 15.797c1.972 8.903 0 17.798-8.92 18.799l-14.82 2.953v217.412c-12.868 6.916-24.734 10.87-34.622 10.87-15.831 0-19.796-4.945-31.654-19.76l-96.944-152.19v147.248l30.677 6.922s0 17.78-24.75 17.78l-68.23 3.958c-1.982-3.958 0-13.832 6.921-15.81l17.805-4.935V210.7l-24.721-1.981c-1.983-8.903 2.955-21.74 16.812-22.736l73.195-4.934 100.889 154.171V198.836l-25.723-2.952c-1.974-10.884 5.927-18.787 15.819-19.767zM42.653 23.919l281.9-20.76c34.618-2.969 43.525-.98 65.283 14.825l89.986 63.247c14.848 10.876 19.797 13.837 19.797 25.693v346.883c0 21.74-7.92 34.597-35.608 36.564L136.64 510.14c-20.785.991-30.677-1.971-41.562-15.815l-66.267-85.978C16.938 392.52 12 380.68 12 366.828V58.495c0-17.778 7.922-32.608 30.653-34.576z"
                    fill-rule="evenodd"
                  />
                </svg>
              </template>
              <template v-slot:title>Notion</template>
              <template v-slot:subtitle
                ><div class="flex">
                  Save in {{ notionDatabaseLabel }} page now
                </div>
              </template>
              <template v-slot:control
                ><m-toggle v-model="exportNotesOption"></m-toggle
              ></template>
            </preference-item>

            <preference-item
              v-if="driveConnected && shareType == 'notes'"
              class="px-3"
            >
              <template v-slot:icon>
                <svg
                  height="24"
                  width="24"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 87.3 78"
                >
                  <path
                    d="m6.6 66.85 3.85 6.65c.8 1.4 1.95 2.5 3.3 3.3l13.75-23.8h-27.5c0 1.55.4 3.1 1.2 4.5z"
                    fill="#0066da"
                  />
                  <path
                    d="m43.65 25-13.75-23.8c-1.35.8-2.5 1.9-3.3 3.3l-25.4 44a9.06 9.06 0 0 0 -1.2 4.5h27.5z"
                    fill="#00ac47"
                  />
                  <path
                    d="m73.55 76.8c1.35-.8 2.5-1.9 3.3-3.3l1.6-2.75 7.65-13.25c.8-1.4 1.2-2.95 1.2-4.5h-27.502l5.852 11.5z"
                    fill="#ea4335"
                  />
                  <path
                    d="m43.65 25 13.75-23.8c-1.35-.8-2.9-1.2-4.5-1.2h-18.5c-1.6 0-3.15.45-4.5 1.2z"
                    fill="#00832d"
                  />
                  <path
                    d="m59.8 53h-32.3l-13.75 23.8c1.35.8 2.9 1.2 4.5 1.2h50.8c1.6 0 3.15-.45 4.5-1.2z"
                    fill="#2684fc"
                  />
                  <path
                    d="m73.4 26.5-12.7-22c-.8-1.4-1.95-2.5-3.3-3.3l-13.75 23.8 16.15 28h27.45c0-1.55-.4-3.1-1.2-4.5z"
                    fill="#ffba00"
                  />
                </svg>
              </template>
              <template v-slot:title>Drive</template>
              <template v-slot:subtitle
                ><div class="flex">
                  Save in 'Meetric meeting notes' Drive folder
                </div>
              </template>
              <template v-slot:control
                ><m-toggle v-model="exportDriveOption"></m-toggle
              ></template>
            </preference-item>

            <preference-item v-if="isHost && !isPrivate" class="px-3">
              <template v-slot:icon>
                <svg
                  class="stroke-current"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="3.0"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M17.788 5.108a9 9 0 1 0 3.212 6.892h-8" />
                </svg>
              </template>
              <template v-slot:title>Calendar</template>
              <template v-slot:subtitle>
                <div class="flex">Update event description now</div>
              </template>
              <template v-slot:control>
                <m-toggle
                  v-if="calendarWritePermission"
                  v-model="saveToCalendarOption"
                ></m-toggle>
                <m-button
                  v-else
                  class=""
                  @click="requestCalendarWrite"
                  button-style="btn-tertiary"
                  segmentName="Connect calendar"
                >
                  Set up
                </m-button>
              </template>
            </preference-item>
          </template>

          <template v-if="nextEventInfo && modalType == 'remind'">
            <!-- <div class="flex items-center" style="height: 2rem">
              <hr class="w-full border-grey6" />
            </div> -->
            <div class="px-3 font-medium mb-2 text-grey1">
              Prepare for
              <a-link :href="'/meetings/' + nextEventInfo.id">
                the next meeting on {{ nextMeetingDateFormatted }}</a-link
              >
            </div>
            <preference-item class="px-3">
              <template v-slot:icon>
                <div style="width: 24px; height: 24px">
                  <svg
                    class="mx-auto"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    width="20"
                    height="20"
                    stroke="none"
                  >
                    <path
                      d="M440 6.5L24 246.4c-34.4 19.9-31.1 70.8 5.7 85.9L144 379.6V464c0 46.4 59.2 65.5 86.6 28.6l43.8-59.1 111.9 46.2c5.9 2.4 12.1 3.6 18.3 3.6 8.2 0 16.3-2.1 23.6-6.2 12.8-7.2 21.6-20 23.9-34.5l59.4-387.2c6.1-40.1-36.9-68.8-71.5-48.9zM192 464v-64.6l36.6 15.1L192 464zm212.6-28.7l-153.8-63.5L391 169.5c10.7-15.5-9.5-33.5-23.7-21.2L155.8 332.6 48 288 464 48l-59.4 387.3z"
                    ></path>
                  </svg>
                </div>
              </template>
              <template v-slot:title>Email</template>
              <template v-slot:subtitle>
                Send to&nbsp;<a-link @click.native="page = 'remindrecipients'"
                  >{{ remindList.length }} recipient<span
                    v-if="remindList.length != 1"
                    >s</span
                  ></a-link
                >&nbsp;a day in advance
              </template>
              <template v-slot:control
                ><m-toggle v-model="remindOption"></m-toggle
              ></template>
            </preference-item>
          </template>
          <template
            v-if="
              nextEventInfo && isHost && !isPrivate && modalType == 'remind'
            "
          >
            <preference-item class="px-3">
              <template v-slot:icon>
                <div style="width: 24px; height: 24px">
                  <svg
                    class="stroke-current"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="3.0"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M17.788 5.108a9 9 0 1 0 3.212 6.892h-8" />
                  </svg>
                </div>
              </template>
              <template v-slot:title>Auto-cancel</template>
              <template v-slot:subtitle>
                Cancel event 2 hours before, if there are no agenda items
              </template>
              <template v-slot:control>
                <m-toggle
                  v-if="hasOfflineAccess"
                  v-model="autoCancelOption"
                ></m-toggle>
                <m-button
                  v-else
                  class=""
                  @click="requestOfflineAccess"
                  button-style="btn-tertiary"
                  segmentName="Request offline access"
                  :pending="offlineConnecting"
                >
                  Set up
                </m-button>
              </template>
            </preference-item>
          </template>
        </div>

        <!-- Notes recipients -->
        <div v-show="page == 'notesrecipients'" class="mx-3">
          <div style="overflow: hidden">
            <transition name="slide">
              <div>
                <div class="bg-grey6 my-2">
                  <end-meeting-modal-list
                    :isPrivate="isPrivate"
                    :attendees="attendees"
                    :userEmail="userEmail"
                    :defaultSubject="
                      shareType == 'notes'
                        ? defaultSubjectNotes
                        : defaultSubjectAgenda
                    "
                    :showAddRecipient="showAddRecipient"
                    listType="notes"
                    @update="onNotesUpdate"
                    @subjectupdate="customSubject = $event"
                  ></end-meeting-modal-list>
                </div>
                <div class="flex mb-2">
                  <m-button
                    class="mr-2"
                    button-style="btn-tertiary"
                    segmentName="Add email recipient"
                    @click="showAddRecipientAndFocus"
                    icon="✉️"
                    >Add recipient</m-button
                  >
                  <m-button
                    button-style="btn-tertiary"
                    segmentName="Preview email"
                    @click="previewEmail"
                    icon="🔍"
                    >Preview email</m-button
                  >
                </div>
              </div>
            </transition>
          </div>
        </div>

        <!-- Reminder recipients -->
        <div v-show="page == 'remindrecipients'" class="mx-3">
          <div style="overflow: hidden">
            <transition name="slide">
              <div class="bg-grey6 my-2">
                <end-meeting-modal-list
                  :isPrivate="isPrivate"
                  :attendees="attendees"
                  :userEmail="userEmail"
                  listType="remind"
                  @update="onRemindUpdate"
                ></end-meeting-modal-list></div
            ></transition>
          </div>
        </div>
      </div>
    </div>
    <template #bottom_modal>
      <div>
        <a-link
          href="https://meetric.notion.site/Share-your-notes-f6b8ae64b6f34a39b40976e8d0417aaa"
          segmentName="Learn more - end meeting"
          >Get help</a-link
        >
        &nbsp; &nbsp;
        <a-link
          href="https://meetric.notion.site/Integrations-Google-Slack-Notion-1d411c6a09154b0d9939a53511ce2860"
          segmentName="Add integrations - end meeting"
          >Add integrations</a-link
        >
      </div>
      <div class="flex">
        <m-button
          v-if="page != 'main'"
          class="text-base self-center"
          button-style="btn-tertiary"
          title="Back to next steps menu"
          @click="onBackClick"
          icon="⬅️"
        >
          <span>Back</span>
        </m-button>
        <m-button
          v-if="page == 'main'"
          class="text-base"
          button-style="btn-primary"
          :class="{
            'bg-base3': isPrivate,
            'opacity-50': noChannelSelected,
          }"
          @click="sendRecap"
          title="Submit and save changes"
          :icon="sendButtonLabel.icon"
          :pending="recapIsSending"
          :disabled="noChannelSelected"
        >
          <span v-html="sendButtonLabel.html"></span>
        </m-button>
      </div>
    </template>
  </m-modal>
</template>

<script>
import MModal from '@/components/UI/MModal';
import PreferenceItem from '@/components/UI/PreferenceItem';
import MSelect from '@/components/UI/MSelect';
import MButton from '@/components/UI/MButton';
import MToggle from '@/components/UI/MToggle';
import ALink from '@/components/UI/ALink.vue';
import EndMeetingModalList from './EndMeetingModalList';
import {
  sendSummary,
  previewSummary,
  exportToNotion,
  exportToDrive,
  sendMessageSlackIntegration,
  getAccess,
  setPublic,
  getMeetingReminder,
  getMeetingAutoCancel,
  grantOfflineAccess,
} from '@/api/Cherry';
import { patchMeeting } from '@/api/Google';
import { getMeetingAttendees, getDatetimeFromEvent } from '@/components/Utils';
import moment from 'moment';
import convertHtmlToSlack from '@/components/htmlToMrkdwn';

export default {
  name: 'EndMeetingModal',
  components: {
    MModal,
    PreferenceItem,
    MButton,
    MSelect,
    MToggle,
    ALink,
    EndMeetingModalList,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    modalType: {
      type: String,
      required: true,
      default: '',
    },
    eventInfo: {
      type: Object,
      required: false,
      default: () => {},
    },
    nextEventInfo: {
      type: Object,
      required: false,
      default: null,
    },
    isPrivate: {
      type: Boolean,
      required: false,
      default: false,
    },
    userEmail: {
      type: String,
      required: true,
    },
    calendarId: {
      type: String,
      required: true,
    },

    noteHTML: {
      type: String,
      required: false,
      default: '',
    },
    agendaItemHTML: {
      type: String,
      required: false,
      default: '',
    },
    isThereReminderForNextMeeting: {
      type: Boolean,
      required: false,
      default: false,
    },
    isThereAutoCancelForNextMeeting: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      page: 'main',
      recipients: [],
      reminderRecipients: [],
      sendNotesOption: true,
      remindOption: false,
      remindOptionDb: false,
      autoCancelOption: false,
      autoCancelOptionDb: false,
      exportNotesOption: false,
      exportDriveOption: false,
      exportSlackOption: false,
      saveToCalendarOption: false,
      sharePageOption: false,
      sharePageOptionDb: false,
      offlineConnecting: false,
      now: moment(), // time when modal opened
      eventStatus: '', // evend end status used for showing reward
      recapIsSending: false,
      showAddRecipient: false,
      shareType: 'notes',
      shareLinkValue: 'edit',
      shareLinkValueDb: 'edit',
      shareLinkOptions: [
        { label: 'Edit', value: 'edit' },
        { label: 'View', value: 'view' },
      ],
      calendarWritePermission: false,
      defaultSubjectNotes: '',
      defaultSubjectAgenda: '',
      customSubject: '',
    };
  },
  watch: {
    shareType: function () {
      this.customSubject = '';
    },
    show: function (newVal) {
      // this is needed to update "now" to current time whenever we open the modal
      if (newVal) {
        this.now = moment();
        this.getPageShareStatus();
        this.eventStatus = this.endMeetingStatus().status;
        if (this.eventStatus == 'early') this.$emit('fetchreward');

        this.shareType = 'notes';
      }
      if (this.nextEventInfo) {
        this.getIsThereReminderForNextMeeting(
          this.calendarId,
          this.nextEventInfo.id,
          this.eventInfo.id
        );
        if (this.isHost) {
          this.getIsThereAutoCancelForNextMeeting(
            this.calendarId,
            this.nextEventInfo.id
          );
        }
      }
    },
    isThereReminderForNextMeeting: function (val) {
      this.remindOption = val;
      this.remindOptionDb = val;
    },
    isThereAutoCancelForNextMeeting: function (val) {
      this.autoCancelOption = val;
      this.autoCancelOptionDb = val;
    },
    remindOptionDb: function (val) {
      this.remindOption = !!this.nextEventInfo && val;
    },
    autoCancelOptionDb: function (val) {
      this.autoCancelOption = !!this.nextEventInfo && this.isHost && val;
    },
    nextEventInfo: function (newVal) {
      this.remindOption = !!newVal && this.remindOptionDb;
      this.autoCancelOption = !!newVal && this.autoCancelOptionDb;
    },
  },
  mounted() {
    this.eventStatus = this.endMeetingStatus().status;
    this.getPageShareStatus();
    this.calendarWritePermission = this.isCalendarWriteGranted();
    this.defaultSubjectNotes = `Notes from ${this.eventInfo.summary}`;
    this.defaultSubjectAgenda = `Agenda from ${this.eventInfo.summary}`;
  },
  computed: {
    isHost() {
      return (
        this.eventInfo?.organizer?.email ===
        (this.$gAuth?.basicProfile?.getEmail() || '')
      );
    },
    currentUrl() {
      // remove query string from URL
      return window.location.href.split('?')[0];
    },
    numOfAgendaItems() {
      let doc = new DOMParser().parseFromString(this.noteHTML, 'text/html');
      const ai = doc.querySelectorAll("div[data-type='agenda_item']");
      return ai.length;
    },
    shareTypeValues() {
      const result = [{ label: 'the full notes', value: 'notes' }];

      if (!this.isPrivate) {
        result.push({ label: 'the page', value: 'page' });
      }
      return result;
    },
    // slackChannelOptions() {
    //   if (this.slackChannelsList) {
    //     return this.slackChannelsList.map((ch) => {
    //       return { label: '#' + ch.name, value: ch.id };
    //     });
    //   }

    //   return [];
    // },

    nextMeetingDateFormatted() {
      return moment(this.nextEventInfo?.start?.dateTime).format('MM/DD/YYYY');
    },
    attendees() {
      return getMeetingAttendees(this.eventInfo);
    },
    sendButtonLabel() {
      // if (this.eventStatus == 'early' || this.eventStatus == 'late') {
      //   return {
      //     html: 'End meeting',
      //     icon: '🎉',
      //   };
      // }

      return { html: 'Submit', icon: '🌐' };
    },
    notionDetails() {
      return this.$store?.getters['notionDetails'];
    },
    notionDatabaseLabel() {
      return this.notionDetails?.database_label || 'Notion';
    },
    notionConnected() {
      return this.notionDetails?.connected && this.notionDetails?.database_id;
    },
    driveDetails() {
      return this.$store?.getters['driveDetails'];
    },
    driveConnected() {
      return this.driveDetails?.connected;
    },
    slackDetails() {
      return this.$store?.getters['slackDetails'];
    },
    slackConnected() {
      return this.slackDetails?.connected;
    },
    sendToList() {
      return this.recipients.filter((u) => u.sendRecap).map((e) => e.email);
    },
    remindList() {
      return this.reminderRecipients
        .filter((u) => u.sendRecap)
        .map((e) => e.email);
    },
    noChannelSelected() {
      if (this.modalType === 'url') {
        return !(
          this.sharePageOption != this.sharePageOptionDb ||
          (this.sharePageOption == this.sharePageOptionDb &&
            this.shareLinkValue != this.shareLinkValueDb)
        );
      } else if (this.modalType === 'note') {
        return !(
          this.sendNotesOption ||
          this.exportNotesOption ||
          this.exportDriveOption ||
          this.exportSlackOption ||
          this.saveToCalendarOption
        );
      } else if (this.modalType === 'remind') {
        return !(
          this.remindOption != this.remindOptionDb ||
          (this.autoCancelOption != this.autoCancelOptionDb && this.isHost)
        );
      }
      return true;
    },
    hasOfflineAccess() {
      return this.$store.getters['hasOfflineAccess'];
    },
  },
  methods: {
    requestOfflineAccess() {
      this.offlineConnecting = true;
      this.$gAuth
        .getAuthCode()
        .then((code) => {
          return grantOfflineAccess(code)
            .then((r) => {
              // response from meetric server

              if (r?.ok === true) {
                this.$store.commit('saveOfflineAccess', true);
                this.autoCancelOption = true;
              }
            })
            .catch(() =>
              this.$snack(
                'Error when obtaining offline access, please refrest page and try again.'
              )
            );
        })
        .catch(() =>
          this.$snack('Please grant offline access to your calendar')
        )
        .finally(() => (this.offlineConnecting = false));
    },
    isCalendarWriteGranted() {
      const scope =
        this.$gAuth?.GoogleAuth?.currentUser?.get().getGrantedScopes() ?? '';
      const x = scope
        .split(' ')
        .some((s) => s === process.env.VUE_APP_GOOGLE_SCOPE_WRITE);
      return x;
    },
    requestCalendarWrite() {
      this.$gAuth?.requestCalendarWrite().then(
        () => {
          // success
          this.calendarWritePermission = this.isCalendarWriteGranted();
        },
        () => {
          // fail
          this.$snack(
            'Please grant the write permission to your calendar so we can update your events'
          );
        }
      );
    },
    getHTMLFromType(shareType) {
      if (shareType == 'agenda') {
        return this.cleanHTML(this.agendaItemHTML);
      }
      return this.cleanHTML(this.noteHTML);
    },
    onClose() {
      setTimeout(() => (this.page = 'main'), 250);
      this.$emit('close');
    },
    cleanHTMLForCalendarAgendaOnly(html) {
      const html2 = html.replace(/ data-recording-sensitive=""/g, '');
      let doc = new DOMParser().parseFromString(html2, 'text/html');

      const agendas = doc.querySelectorAll('div[data-type="agenda_item"]');

      const div = document.createElement('div');
      let ul = document.createElement('ul');

      for (const agenda of agendas) {
        const completed = agenda.getAttribute('data-completed') === 'true';

        if (agenda.firstChild?.nodeName === 'P') {
          const li = document.createElement('li');

          li.innerHTML =
            `${completed ? '<s>' : ''}` +
            agenda.firstChild.innerHTML +
            `${completed ? '</s>' : ''}`;
          ul.appendChild(li);
        } else {
          // h2, h2
          if (ul.childElementCount) {
            div.append(ul);
            ul = document.createElement('ul');
          }
          if (completed) {
            agenda.innerHTML = `<s>${agenda.innerHTML}</s>`;
          }
          div.append(agenda.firstChild);
        }
      }

      if (ul.childElementCount) div.append(ul);

      return div.innerHTML;
    },
    cleanHTMLForCalendar(html) {
      const html2 = html.replace(/ data-recording-sensitive=""/g, '');

      let doc = new DOMParser().parseFromString(html2, 'text/html');
      const actions = doc.querySelectorAll('div[data-type="action_item"]');
      for (const action of actions) {
        const symbol =
          action.getAttribute('data-completed') === 'true' ? '☑︎ ' : '☐ ';
        const paragraph = action.firstChild;

        paragraph.insertBefore(
          document.createTextNode(symbol),
          paragraph.firstChild
        );

        if (
          action.parentNode.nodeName === 'LI' &&
          action.firstChild.nodeName === 'P'
        ) {
          const span = document.createElement('span');
          span.innerHTML = action.firstChild.innerHTML;
          action.parentNode.replaceChild(span, action);
        }
      }

      const agendas = doc.querySelectorAll('div[data-type="agenda_item"]');
      for (const agenda of agendas) {
        const parent = agenda.parentNode;
        const completed = agenda.getAttribute('data-completed') === 'true';
        if (parent.nodeName !== 'LI') {
          // NOT in list
          if (agenda.firstChild?.nodeName === 'P') {
            const ul = document.createElement('ul');
            const li = document.createElement('li');

            li.innerHTML =
              `${completed ? '<s>' : ''}` +
              agenda.firstChild.innerHTML +
              `${completed ? '</s>' : ''}`;
            ul.appendChild(li);
            parent.replaceChild(ul, agenda);
          } else {
            // h1 or h2
            if (completed) {
              agenda.innerHTML = `<s>${agenda.innerHTML}</s>`;
            }
          }
        } else {
          // in list

          const span = document.createElement('span');
          const content =
            agenda.firstChild?.nodeName === 'P'
              ? agenda.firstChild?.innerHTML || ''
              : agenda.firstChild?.outerHTML || '';

          span.innerHTML =
            `${completed ? '<s>' : ''}` +
            content +
            `${completed ? '</s>' : ''}`;
          parent.replaceChild(span, agenda);
        }
      }

      const lis = doc.querySelectorAll('li');
      for (const li of lis) {
        if (li.firstChild?.nodeName === 'P') {
          const span = document.createElement('span');
          span.innerHTML = li.firstChild.innerHTML;
          li.replaceChild(span, li.firstChild);
        }
      }

      // replace images with links only
      const images = doc.querySelectorAll('img');
      for (const img of images) {
        const link = document.createElement('a');
        link.href = img.src;
        link.innerHTML = 'Image';

        img.parentNode.replaceChild(link, img);
      }

      return doc.body.innerHTML;
    },
    cleanHTML(html) {
      const html2 = html.replace(/ data-recording-sensitive=""/g, '');
      let doc = new DOMParser().parseFromString(html2, 'text/html');
      const uls = doc.querySelectorAll('ul');
      for (const ul of uls) {
        for (const li of ul.children) {
          if (
            li.firstChild.getAttribute('data-type') == 'action_item' ||
            li.firstChild.getAttribute('data-type') == 'agenda_item'
          ) {
            li.classList.add('specialitem-child');
          }
        }
      }
      const ols = doc.querySelectorAll('ol');
      for (const ol of ols) {
        let value = 1;
        for (const li of ol.children) {
          if (
            li.firstChild.getAttribute('data-type') == 'action_item' ||
            li.firstChild.getAttribute('data-type') == 'agenda_item'
          ) {
            li.classList.add('specialitem-child');
          } else {
            li.setAttribute('value', value++);
          }
        }
      }
      // replace images with links only
      const images = doc.querySelectorAll('img');
      for (const img of images) {
        const link = document.createElement('a');
        link.href = img.src;
        link.innerHTML = 'Image';

        img.parentNode.replaceChild(link, img);
      }

      return doc.body.innerHTML;
    },
    getPageShareStatus() {
      getAccess(this.calendarId, this.eventInfo.id).then((data) => {
        if (!data.error) {
          this.sharePageOption = data.public_read;
          this.sharePageOptionDb = data.public_read;
          this.shareLinkValue = data.public_write ? 'edit' : 'view';
          this.shareLinkValueDb = data.public_write ? 'edit' : 'view';

          this.$store?.commit('setActiveEventOptions', {
            public_read: data.public_read,
          });
          this.$emit('publicaccess', data.public_read);
        }
      });
    },
    getIsThereReminderForNextMeeting(calId, nextMeetingId, meetingId) {
      getMeetingReminder(calId, nextMeetingId, meetingId).then((r) => {
        this.remindOptionDb = r;
      });
    },
    getIsThereAutoCancelForNextMeeting(calId, nextMeetingId) {
      getMeetingAutoCancel(calId, nextMeetingId).then((r) => {
        this.autoCancelOptionDb = r;
      });
    },
    setShareLinkOptions() {
      return setPublic(
        this.calendarId,
        this.eventInfo.id,
        this.sharePageOption,
        this.shareLinkValue == 'edit',
        this.eventInfo
      )
        .then((r) => {
          this.sharePageOption = r.public_read;
          this.$store?.commit('setActiveEventOptions', {
            public_read: r.public_read,
          });
          if (this.sharePageOption) {
            const shareType =
              this.shareLinkValue == 'edit' ? 'view + edit' : 'view only';
            return {
              name: 'share',
              ok: true,
              text: `This page is now public (${shareType})`,
            };
          } else {
            return { name: 'share', ok: true, text: `` };
          }
        })
        .catch((e) => {
          console.log(e);
          this.$snack({ text: 'Error setting sharing status', emoji: '😨' });
        });
    },
    copyLink() {
      navigator.clipboard.writeText(this.currentUrl).then(
        function () {
          this.$snack({ text: 'Link copied', emoji: '🩹' });
        }.bind(this),
        function (err) {
          console.error('Could not copy text to clipboard', err);
        }
      );
    },
    getDefaultShareType(event) {
      const eventInFuture = moment().isBefore(getDatetimeFromEvent(event));
      return eventInFuture && this.numOfAgendaItems ? 'agenda' : 'notes';
    },
    onBackClick() {
      this.page = 'main';
      this.showAddRecipient = false;
    },
    showAddRecipientAndFocus() {
      this.showAddRecipient = true;
      this.$nextTick(() => {
        document.querySelector('#addRecipientInput').focus();
      });
    },

    getAllRecipients() {
      // this is needed to get copy without changing original objects
      var recipients = JSON.parse(JSON.stringify(this.recipients));

      recipients.forEach((r) => {
        if (this.modalType != 'note') {
          // if we sharing, doesn't matter if send is toggled
          r.sendRecap = false;
        }

        if (this.modalType != 'remind' || !this.remindOption) {
          r.setReminder = false;
        } else {
          if (this.remindList.includes(r.email)) {
            r.setReminder = true;
          } else r.setReminder = false;
        }
      });

      return recipients;
    },
    onNotesUpdate(recipients) {
      this.recipients = recipients; // update local
    },
    onRemindUpdate(recipients) {
      this.reminderRecipients = recipients; // update local
    },
    endMeetingStatus() {
      // all day event
      if (!this.eventInfo?.end?.dateTime || !this.eventInfo?.start?.dateTime)
        return { status: 'other', minutes: 0 };

      const now = moment();
      const start = moment(this.eventInfo.start.dateTime);
      const end = moment(this.eventInfo.end.dateTime);
      const endPlusSixty = moment(this.eventInfo.end.dateTime).add(
        60,
        'minutes'
      );

      const difference = end.diff(now, 'minutes', true);
      if (now.isBetween(start, end, 'minute')) {
        //early
        return { status: 'early', minutes: Math.ceil(difference) };
      } else if (now.isBetween(start, endPlusSixty, 'minute')) {
        // late or on time
        const minutes = difference > -1 ? 0 : Math.floor(difference);
        return { status: 'late', minutes: minutes };
      }

      return { status: 'other', minutes: 0 };
    },
    exportNotion(shareType) {
      const html = this.getHTMLFromType(shareType);

      const payload = {
        recipients: this.recipients,
        share_type: shareType,
        meeting_html: html,
        event_info: this.eventInfo,
        private: this.isPrivate,
      };

      return exportToNotion(payload, this.calendarId, this.eventInfo.id)
        .then(() => {
          //this.$notification('');
          window.meetric.track('Notion note saved');
          return { name: 'notion', ok: true };
        })
        .catch(() => {
          this.$notification('Error saving note. Check Notion configuration', {
            type: 'error',
            closable: true,
            errorMsg: 'notionerror-invalidconfig',
          });
          return { name: 'notion', ok: false };
        });
    },
    exportDrive(shareType) {
      const html = this.getHTMLFromType(shareType);

      const payload = {
        recipients: this.recipients,
        share_type: shareType,
        meeting_html: html,
        event_info: this.eventInfo,
        private: this.isPrivate,
      };

      return exportToDrive(payload, this.calendarId, this.eventInfo.id)
        .then(() => {
          window.meetric.track('Drive note saved');
          return { name: 'drive', ok: true };
        })
        .catch(() => {
          this.$notification('Error saving note. Check Drive configuration', {
            type: 'error',
            closable: true,
            errorMsg: 'driveerror-invalidconfig',
          });
          return { name: 'drive', ok: false };
        });
    },
    exportSlack(shareType) {
      if (!this.slackDetails?.default_channel?.id) {
        this.$snack('No Slack channel were selected. Message not sent!');
        return;
      }

      const html = convertHtmlToSlack(this.getHTMLFromType(shareType));

      const payload = {
        ...html,
        share_type: shareType,
        channel: this.slackDetails.default_channel.id,
        event_info: this.eventInfo,
      };
      return sendMessageSlackIntegration(payload)
        .then((r) => {
          if (r?.ok) {
            return { name: 'slack', ok: true };
          } else {
            throw 'error'; // go to catch branch below
          }
        })
        .catch(() => {
          this.$snack('Error while sending message to Slack');
          return { name: 'slack', ok: false };
        });
    },
    saveToCalendar(shareType) {
      let desc = '';
      if (shareType == 'agenda') {
        desc = this.cleanHTMLForCalendarAgendaOnly(this.agendaItemHTML);
      } else {
        desc = this.cleanHTMLForCalendar(this.noteHTML);
      }

      const url = `${process.env.VUE_APP_PUBLIC_ENDPOINT}meetings/${this.eventInfo?.id}`;
      desc += `<br><p>Access the meeting notes: <a href="${url}">${url}</a></p>`;
      console.log(desc);

      const opt = { description: desc };
      return patchMeeting(this.calendarId, this.eventInfo?.id, opt).then(
        (r) => {
          if (r) {
            return { name: 'eventDesc', ok: true };
          } else {
            this.$snack(
              'Error while saving to your calendar, please refresh and try again'
            );
            return { name: 'eventDesc', ok: false };
          }
        }
      );
    },
    async sendRecap() {
      if (this.shareType == 'agenda') {
        // we do not want Notion for agenda
        this.exportNotesOption = false;
        this.exportDriveOption = false;
      }
      let trackShareType = this.modalType == 'url' ? 'page' : 'notes';

      const endMeeting = this.endMeetingStatus();
      const trackProps = {
        isPrivate: this.isPrivate,
        endMeeting: endMeeting.status,
        type: 'Button click',
        shareType: trackShareType,
      };

      let payload = {
        recipients: this.getAllRecipients(),
        event_info: this.eventInfo,
        // we need this for both notes and reminder
        private: this.isPrivate,
      };
      this.recapIsSending = true;

      let promises = [];

      if (this.modalType === 'url') {
        promises.push(this.setShareLinkOptions());
      } else if (this.modalType === 'note') {
        // agenda or notes
        if (this.exportNotesOption) {
          promises.push(this.exportNotion(this.shareType));
          trackProps.exportNotion = true;
        }
        if (this.exportDriveOption) {
        // TODO: follow these
          promises.push(this.exportDrive(this.shareType));
          trackProps.exportDrive = true;
          }
        if (this.exportSlackOption) {
          promises.push(this.exportSlack(this.shareType));
          trackProps.exportSlack = true;
        }
        if (this.saveToCalendarOption) {
          promises.push(this.saveToCalendar(this.shareType));
          trackProps.saveToCalendar = true;
        }

        const additionalNote = document.getElementById(
          `meetric-meeting-note-${this.isPrivate}`
        ).innerHTML;

        if (this.sendNotesOption) {
          payload.additional_note = additionalNote;
          payload.meeting_html = this.getHTMLFromType(this.shareType);
          payload.share_type = this.shareType;
          payload.subject = this.customSubject; // custom or empty -> empty==default subject in backend
          trackProps.recap = true;
        }
      }
      payload.remind_next = {
        event_info: this.nextEventInfo,
        offset: 1320, // 22h in minutes
      };
      payload.auto_cancel = this.autoCancelOption;
      if (this.modalType == 'remind') {
        trackProps.autocancel = this.autoCancelOption;
        if (this.remindOption) {
          trackProps.remind = true;
        } else {
          trackProps.remind = false;
        }
      }

      if (
        this.autoCancelOptionDb != this.autoCancelOption ||
        this.remindOptionDb != this.remindOption ||
        (this.modalType != 'url' && this.sendNotesOption)
      ) {
        if (this.modalType != 'remind') {
          payload.auto_cancel = this.autoCancelOptionDb;
        }
        promises.push(
          sendSummary(payload, this.calendarId, this.eventInfo.id).then(
            () => {
              // timer used to prevent repeated click before hiding modal
              // setTimeout(() => {
              //   this.recapIsSending = false;
              // }, 500);
              return { name: 'email', ok: true };
            },
            () => {
              this.$snack('Error while sending emails, please try again');
              return { name: 'email', ok: false };
              // this.recapIsSending = false;
              // window.meetric.track('Send recap', trackProps);
              // return;
            }
          )
        );
      }
      const ok = {
        slack: true,
        notion: true,
        drive: true,
        eventDesc: true,
        email: true,
      };
      let sharePageText = '';
      const res = await Promise.allSettled(promises);
      res.forEach((r) => {
        if (r.value.name == 'share') sharePageText = r.value.text;
        else return (ok[r.value.name] = r.value.ok);
      });
      this.recapIsSending = false;

      this.$emit('sent', {
        shareType: this.shareType,
        sharePageText: sharePageText,
        end: endMeeting,
        recipientsCount: this.recipients.filter((r) => r.sendRecap).length,
        recapOn: this.modalType == 'note' && this.sendNotesOption && ok.email,
        reminderOn: this.remindOption,
        reminderWas: this.remindOptionDb,
        autocancelOn: this.autoCancelOption,
        autocancelWas: this.autoCancelOptionDb,
        eventDescOn:
          this.modalType == 'note' &&
          this.saveToCalendarOption &&
          ok.eventDesc &&
          !this.isPrivate,
        slackOn: this.modalType == 'note' && this.exportSlackOption && ok.slack,
        driveOn: this.modalType == 'note' && this.exportDriveOption && ok.drive, //TODO: follow
        notionOn:
          this.modalType == 'note' && this.exportNotesOption && ok.notion,
        reminderCount: this.remindList.length,
      });

      window.meetric.track('Send recap', trackProps);
    },
    previewEmail() {
      //const recipients = this.recipients.filter((e) => e.sendRecap);
      const additionalNote = document.getElementById(
        `meetric-meeting-note-${this.isPrivate}`
      ).innerHTML;

      const html = this.getHTMLFromType(this.shareType);

      const payload = {
        recipients: this.recipients,
        additional_note: additionalNote,
        meeting_html: html,
        event_info: this.eventInfo,
        share_type: this.shareType,
      };
      payload.remind_next = {
        event_info: this.nextEventInfo,
        offset: 1320, // 22h in minutes
      };
      var wnd = window.open('about:blank', '_blank', '');
      wnd.document.title = 'Loading email preview';

      previewSummary(payload, this.calendarId, this.eventInfo.id).then(
        (result) => {
          wnd.document.write(result.message_body);
          wnd.document.title = 'Email preview';
        }
      );
    },
  },
};
</script>

<style scoped>
@screen lg {
  .modal-content {
    /*height: calc(80vh - 170px);*/
    @apply overflow-auto;
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.3s ease-out;
}
.slide-enter,
.slide-leave-to {
  transform: translateY(-100%);
}
.share-url:focus {
  text-overflow: clip;
  -webkit-user-select: all; /* Chrome all / Safari all */
  -moz-user-select: all; /* Firefox all */
  -ms-user-select: all; /* IE 10+ */
  user-select: all; /* Likely future */
}
.dark .share-url {
  background-color: rgb(48, 48, 48);
}
</style>
